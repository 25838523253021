import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IUspIcons {
  section: MarkdownRemarkFrontmatterSections;
}

export const UspIcons: React.FunctionComponent<IUspIcons> = ({ section }) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const { frontmatter } = section.componentId;
  return (
    <MaxWidthWithPadding container={true} justify='space-between'>
      {frontmatter.icons?.map((icon, index) => (
        <Grid
          key={index}
          container={true}
          direction='column'
          item={true}
          sm={2}
          xs={6}
        >
          <Grid item={true} container={true} justify='center'>
            <img
              src={icon?.icon}
              alt={icon?.title || 'image icon'}
              width='90'
              height='90'
            />
          </Grid>
          <IconContainer item={true} container={true} justify='center'>
            <Typography variant='h3'>{icon?.title}</Typography>
          </IconContainer>
        </Grid>
      ))}
    </MaxWidthWithPadding>
  );
};

const MaxWidthWithPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(6)}px ${theme.spacing(0)}`,
  },
}))(MaxWidthContainer);

const IconContainer = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}`,
  },
}))(Grid);
