import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface Iquote {
  section: MarkdownRemarkFrontmatterSections;
}

export const Quote: React.FunctionComponent<Iquote> = ({ section }) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }

  const { frontmatter } = section.componentId;

  return (
    <MaxWidthContainerPadding container={true} justify='center'>
      <Grid>
        <Typography
          style={{
            fontStyle: 'italic',
            fontSize: '2rem',
            textAlign: 'center',
            lineHeight: '1.5',
          }}
        >
          {frontmatter.quote}
        </Typography>
      </Grid>
      <Grid>
        <Typography
          style={{
            fontWeight: 'bold',
            fontSize: '1.5rem',
            textAlign: 'center',
            lineHeight: '2',
            marginTop: '30px',
          }}
        >
          {frontmatter.name}
        </Typography>
      </Grid>
    </MaxWidthContainerPadding>
  );
};

const MaxWidthContainerPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(6)}px ${theme.spacing(12)}px`,
  },
}))(MaxWidthContainer);
