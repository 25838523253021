import { Grid, Typography, SvgIcon, Link } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { PropertySpecs } from '../../components/PropertySpecs/PropertySpecs';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import {
  MarkdownRemarkConnection,
  MarkdownRemarkFrontmatterSections,
} from '../../types/graphqlTypes';

interface IProperties {
  section: MarkdownRemarkFrontmatterSections;
  properties: MarkdownRemarkConnection;
}

export const Properties: React.FunctionComponent<IProperties> = ({
  properties,
}) => {
  if (!properties || !properties.nodes || !properties.nodes.length) {
    return <p>content invalid</p>;
  }

  const { nodes } = properties;

  return (
    <BrownContainer container={true} justify='center'>
      <MaxWidthContainer
        xs={10}
        sm={12}
        item={true}
        container={true}
        justify='center'
      >
        <Grid xs={12} item={true} container={true} alignItems='center'>
          <Title variant='h2'>Aanbod</Title>
        </Grid>
        <Grid container={true} item={true} spacing={2} xs={12}>
          {nodes.map((propertyObj, index) => {
            const { frontmatter: property } = propertyObj;
            return (
              <PropertyCard
                container={true}
                item={true}
                direction='row'
                sm={4}
                spacing={1}
                key={index}
              >
                <ImageContainer
                  xs={12}
                  item={true}
                  style={{
                    background: `url(${
                      property?.photos?.[0]?.photo
                        ? property.photos[0].photo
                        : 'https://via.placeholder.com/150'
                    })`,
                  }}
                >
                  <RoundButton
                    color='default'
                    href={`/aanbod/${property.street}-${property.houseNumber}-${property.city}`}
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '10px',
                    }}
                  >
                    <SvgIcon component={ArrowForwardIosIcon} />
                  </RoundButton>
                </ImageContainer>
                <PropertySpecs
                  street={property.street}
                  houseNumber={property.houseNumber}
                  city={property.city}
                  sqm={property.sqm}
                  rooms={property.rooms}
                  price={property.price}
                />
              </PropertyCard>
            );
          })}
        </Grid>
      </MaxWidthContainer>
    </BrownContainer>
  );
};

const BrownContainer = withStyles(theme => ({
  root: {
    width: '100%',
    background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) no-repeat 10vw 0`,
    padding: `${theme.spacing(6)}px ${theme.spacing(0)}`,
    [theme.breakpoints.down('xs')]: {
      background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) no-repeat 0 0`,
    },
  },
}))(Grid);

const ImageContainer = withStyles(() => ({
  root: {
    backgroundSize: 'cover',
    width: '100%',
    height: '300px',
    position: 'relative',
  },
}))(Grid);

const Title = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}`,
  },
}))(Typography);

const GoToPropertyButton = withStyles(theme => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))(RoundButton);

const PropertyCard = withStyles(theme => ({
  root: {
    paddingBottom: `${theme.spacing(6)}px !important`,
  },
}))(Grid);
