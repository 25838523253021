import { Grid, makeStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface ITeam {
  section: MarkdownRemarkFrontmatterSections;
}

export const Team: React.FunctionComponent<ITeam> = ({ section }) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const { frontmatter } = section.componentId;
  return (
    <BrownContainer container={true} justify='center'>
      <MaxWidthWithPadding
        item={true}
        xs={10}
        sm={12}
        container={true}
        justify='center'
      >
        {frontmatter.team?.map((icon, index) => (
          <Grid
            key={index}
            container={true}
            direction='column'
            item={true}
            sm={2}
            xs={6}
          >
            <Grid item={true} container={true} justify='center'>
              <img
                src={icon?.icon}
                alt={icon?.title || 'image icon'}
                width='120px'
                style={{ borderRadius: '50%' }}
              />
            </Grid>
            <IconContainer item={true} container={true} justify='center'>
              <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
                <Typography variant='h3'>{icon?.name}</Typography>
              </Grid>
              <Grid item={true} xs={12} style={{ textAlign: 'center' }}>
                <Typography variant='body'>{icon?.function}</Typography>
              </Grid>
            </IconContainer>
          </Grid>
        ))}
      </MaxWidthWithPadding>
    </BrownContainer>
  );
};

const MaxWidthWithPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(6)}px ${theme.spacing(0)}`,
  },
}))(MaxWidthContainer);

const IconContainer = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}`,
  },
}))(Grid);

const BrownContainer = withStyles(theme => ({
  root: {
    width: '100%',
    background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) no-repeat 10vw 0`,
    padding: `${theme.spacing(6)}px ${theme.spacing(0)}`,
    [theme.breakpoints.down('xs')]: {
      background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.primary.main}) no-repeat 0 0`,
    },
  },
}))(Grid);
