import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../blocks/Footer/Footer';
import { Navigation } from '../blocks/Navigation/Navigation';
import { PageBySlugQuery } from '../types/graphqlTypes';

import Components from './Components';
import { Layout } from './Layout';

interface IPageDefaultTemplateProps extends PageProps {
  data: PageBySlugQuery;
}

const PageDefaultTemplate: React.FunctionComponent<IPageDefaultTemplateProps> =
  ({ data }) => {
    if (
      !data ||
      !data.markdownRemark ||
      !data.markdownRemark.frontmatter ||
      !data.markdownRemark.frontmatter.sections
    ) {
      return <p>no data</p>;
    }
    const { frontmatter } = data.markdownRemark;
    const { sections } = frontmatter;
    const { properties } = data;
    return (
      <>
        <Helmet
          htmlAttributes={{
            lang: 'nl',
          }}
        >
          <title>{frontmatter.title}</title>
          <meta name='description' content={frontmatter.description ?? ''} />
        </Helmet>
        <Layout>
          <Navigation />
          {/* @ts-ignore TODO: fix this */}
          <Components sections={sections} properties={properties} />
          {data?.footer?.frontmatter && (
            <Footer section={data.footer.frontmatter} />
          )}
        </Layout>
      </>
    );
  };

export default PageDefaultTemplate;

export const pageQuery = graphql`
  query PageBySlug($urlPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $urlPath } }) {
      id
      html
      frontmatter {
        title
        description
        path
        sections {
          componentId {
            html
            frontmatter {
              type
              flip
              name
              componentId
              leaderImage
              image
              quote
              buttons {
                buttonText
                link
              }
              infoImage
              title
              text
              email
              street
              postcode
              city
              links {
                title
                url
              }
              icons {
                icon
                title
              }
              team {
                icon
                name
                function
              }
              reviews {
                name
                review
              }
              selectedProperties {
                property {
                  componentId {
                    frontmatter {
                      street
                      houseNumber
                      location
                      price
                      sqm
                      city
                      description
                      rooms
                      characteristics {
                        sectionTitle
                        values {
                          key
                          value
                        }
                      }
                      photos {
                        photo
                        description
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    footer: markdownRemark(frontmatter: { type: { eq: "footer" } }) {
      frontmatter {
        name
        phone
        email
        postcode
        city
        street
        links {
          title
          url
        }
      }
    }
    properties: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "property" } } }
    ) {
      nodes {
        frontmatter {
          street
          houseNumber
          location
          price
          sqm
          city
          description
          rooms
          photos {
            photo
            description
          }
        }
      }
    }
  }
`;
