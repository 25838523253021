import * as React from 'react';

import { Contact } from '../blocks/Contact/Contact';
import { Footer } from '../blocks/Footer/Footer';
import { InfoBlock } from '../blocks/InfoBlock/InfoBlock';
import { LeaderImage } from '../blocks/LeaderImage/LeaderImage';
import { Properties } from '../blocks/Properties/Properties';
import { PropertySelection } from '../blocks/PropertySelection/PropertySelection';
import { Quote } from '../blocks/Quote/Quote';
import { Reviews } from '../blocks/Reviews/Reviews';
import { Team } from '../blocks/Team/Team';
import { UspIcons } from '../blocks/UspIcons/UspIcons';
import { MarkdownRemarkFrontmatterSections } from '../types/graphqlTypes';

interface IComponentsProps {
  sections: Array<MarkdownRemarkFrontmatterSections>;
}

const componentsRegistry = {
  leaderImage: LeaderImage,
  infoBlock: InfoBlock,
  uspIcons: UspIcons,
  propertySelection: PropertySelection,
  reviews: Reviews,
  contact: Contact,
  footer: Footer,
  quote: Quote,
  properties: Properties,
  team: Team,
};

const Components: React.FunctionComponent<IComponentsProps> = ({
  sections,
  properties,
}) => {
  const sectionComponents = sections.map(section => {
    if (
      !section ||
      !section.componentId ||
      !section.componentId.frontmatter ||
      !section.componentId.frontmatter.type
    ) {
      return null;
    }
    const Component =
      componentsRegistry[
        section.componentId.frontmatter.type as keyof typeof componentsRegistry
      ];

    if (section.componentId.frontmatter.type === 'properties') {
      return (
        <Properties properties={properties} key='component-property-section' />
      );
    }

    if (Component) {
      return (
        <Component
          section={section}
          key={`component-${section.componentId.frontmatter.componentId}`}
        />
      );
    }
    return null;
  });
  return <>{sectionComponents}</>;
};

export default Components;
