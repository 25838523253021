import { Grid, SvgIcon, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withStyles } from '@material-ui/styles';
import React, { useEffect } from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IPropertySelection {
  section: MarkdownRemarkFrontmatterSections;
}

export const Reviews: React.FunctionComponent<IPropertySelection> = ({
  section,
}) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const [currentReviewIndex, setCurrentReviewIndex] = React.useState<number>(0);

  const { reviews } = section.componentId.frontmatter;

  const currentReview = reviews[currentReviewIndex];

  const nextReview = () => {
    if (currentReviewIndex === reviews.length - 1) {
      setCurrentReviewIndex(0);
    } else {
      setCurrentReviewIndex(currentReviewIndex + 1);
    }
  };

  const previousReview = () => {
    if (currentReviewIndex === 0) {
      setCurrentReviewIndex(reviews.length - 1);
    } else {
      setCurrentReviewIndex(currentReviewIndex - 1);
    }
  };

  useEffect(() => {
    const changeReviewInterval = setInterval(nextReview, 5000);
    return () => {
      clearInterval(changeReviewInterval);
    };
  });

  return (
    <MaxWidthContainerPadding container={true}>
      <Review xs={12} item={true}>
        <Grid>
          <Typography style={{ fontStyle: 'italic' }}>
            {currentReview.review}
          </Typography>
          <Typography variant='h4'>{currentReview.name}</Typography>
        </Grid>
        <SwitchButtons container={true} spacing={2}>
          <Grid item={true}>
            <RoundButton onClick={() => previousReview()}>
              <SvgIcon component={ArrowBackIosIcon} />
            </RoundButton>
          </Grid>
          <Grid item={true}>
            <RoundButton onClick={() => nextReview()}>
              <SvgIcon component={ArrowForwardIosIcon} />
            </RoundButton>
          </Grid>
        </SwitchButtons>
      </Review>
    </MaxWidthContainerPadding>
  );
};

const Review = withStyles(theme => ({
  root: {
    borderLeft: `2px solid black`,
    margin: theme.spacing(5, 0),
    padding: theme.spacing(4, 8),
  },
}))(Grid);

const SwitchButtons = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))(Grid);

const MaxWidthContainerPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(6)}px 0`,
  },
}))(MaxWidthContainer);
