import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React from 'react';

import { MaxWidthContainer } from '../../components/MaxWidthContainer/MaxWidthContainer';
import FrameImage from '../../images/frame1.svg';
import { MarkdownRemarkFrontmatterSections } from '../../types/graphqlTypes';

interface IInfoBlockProps {
  section: MarkdownRemarkFrontmatterSections;
}

export const InfoBlock: React.FunctionComponent<IInfoBlockProps> = ({
  section,
}) => {
  if (!section || !section.componentId || !section.componentId.frontmatter) {
    return <p>content invalid</p>;
  }
  const { frontmatter } = section.componentId;
  const { html } = section.componentId;
  return (
    <MaxWidthWithPadding
      container={true}
      direction={frontmatter.flip ? 'row-reverse' : 'row'}
    >
      <Grid item={true} sm={6} container={true}>
        <ImageContainer
          item={true}
          sm={10}
          container={true}
          justify='center'
          alignItems='center'
        >
          <img
            // @ts-ignore
            src={frontmatter.infoImage}
            alt={frontmatter.title || 'leader'}
            style={{
              width: '100%',
            }}
          />
        </ImageContainer>
      </Grid>
      <Grid item={true} sm={6}>
        <HeaderText variant='h2'>{frontmatter.title}</HeaderText>
        <Typography>
          <div className='content' dangerouslySetInnerHTML={{ __html: html }} />
        </Typography>
      </Grid>
    </MaxWidthWithPadding>
  );
};

const ImageContainer = withStyles({
  root: {
    backgroundImage: `url(${FrameImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left bottom',
    backgroundSize: '90% 90%',
    padding: '0 0 50px 40px',
    '& img': {
      width: '100%',
    },
  },
})(Grid);

const MaxWidthWithPadding = withStyles(theme => ({
  root: {
    padding: `${theme.spacing(6)}px ${theme.spacing(0)}`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
}))(MaxWidthContainer);

const HeaderText = withStyles(theme => ({
  root: {
    margin: `${theme.spacing(2)}px 0`,
  },
}))(Typography);
